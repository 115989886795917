import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout/layout"
import SEO from "../components/SEO/seo"

import TableIcon from "../images/icon-table-4.png"

import "./index.scss"

const IndexPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <div className="section-title">
        <span>Popular Economic Reports for Northwest Arkansas</span>
      </div>
      <div className="home-options">
        <Link to="/occupation-table/" className="home-option">
          <img src={TableIcon} />
          <h2>Occupation Table</h2>
          <p>
            A table displaying occupations with data related to jobs, growth,
            earning, completions, demographics, and more.
          </p>
        </Link>
        <Link to="/industry-table/" className="home-option">
          <img src={TableIcon} />
          <h2>Industry Table</h2>
          <p>
            A table displaying industries with data related to jobs, growth,
            earning, completions, demographics, and more.
          </p>
        </Link>
        <Link to="/demographics-table/" className="home-option">
          <img src={TableIcon} />
          <h2>Population Demographic Table</h2>
          <p>
            A table of demographic and population data including age cohort,
            gender, and race/ethnicity.
          </p>
        </Link>
        <Link to="/program-table/" className="home-option">
          <img src={TableIcon} />
          <h2>Program Table</h2>
          <p>
            A table of the academic programs in a region with data related to
            completions, openings, earnings, jobs, and more.
          </p>
        </Link>
      </div>
    </Layout>
  )
}

export default IndexPage
